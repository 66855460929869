import React from 'react'
import * as styles from "./banners.module.css"
import { FaQuestionCircle } from "react-icons/fa";
import { IoWarning } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import TierLink from './tierlink';
import CommonBanner from './commonbanner';

const HostedVpnBanner = ({tiers}) => {
  
  //TODO: useMemo
  const currentTier = tiers === undefined ? undefined : tiers.find(tier => tier.isInUse)
  const premiumTier = tiers === undefined ? undefined : tiers.find(tier => tier.tierId === 4)
  const downgradeTier = currentTier === undefined ? undefined : tiers.find(tier => tier.tierId === currentTier.downgradesToTier)
  const isFree = currentTier === undefined ? true : !currentTier.purchasable
  const isBase = currentTier === undefined ? true : currentTier.tierId === downgradeTier.tierId
  const timeExpires = new Date()
  if (currentTier !== undefined && currentTier.hasOwnProperty("currentPeriodEnd")) {
    timeExpires.setTime(currentTier.currentPeriodEnd)
  }
  const dateExpires = timeExpires.getDate() + "." + (timeExpires.getMonth() + 1) + "." + timeExpires.getFullYear()
  const numLocations = currentTier === undefined ? undefined : currentTier.maxTotalLocations === -1 ? "unlimited" : currentTier.maxTotalLocations

  console.log("Current tier: ", currentTier)
  console.log("Downgrade tier: ", downgradeTier)
  console.log("isFree: ", isFree)
  console.log("isBase: ", isBase)

  const baseTierContent = () =>
    <> { currentTier !== undefined &&
      <div className={styles.freeTier}>
        <div className={styles.warning}><span><IoWarning /></span></div>
        Your current plan is <TierLink tier={currentTier}>{currentTier.tierName}</TierLink>. You can only view available locations. 
        Consider purchasing our <TierLink tier={premiumTier} premium >Premium plan</TierLink>.
      </div> }
    </>

  const freeTierContent = () =>
    <> { currentTier !== undefined &&
      <div className={styles.freeTier}>
        <div className={styles.warning}><span><IoWarning /></span></div>
        Your current plan is <TierLink tier={currentTier}>{currentTier.tierName}</TierLink>, which will expire on <strong>{dateExpires}</strong>. 
        During trial period you can create <strong>{ numLocations }</strong> configs for different locations.
        After trial period ends, you plan will be downgraded to <TierLink tier={downgradeTier}>{downgradeTier.tierName}</TierLink>. 
        To avoid service interruption, consider purchasing our <TierLink tier={premiumTier} premium >Premium plan</TierLink>.
      </div> }
    </>

  const premiumTierContent = () =>
    <> { currentTier !== undefined &&
      <div className={styles.premiumTier}>
        <div className={styles.check}><span><FaCheckCircle /></span></div>
        Your current plan is <TierLink tier={premiumTier} premium >Premium</TierLink>.
      </div> }
    </>

  const content = isFree ? (isBase ? baseTierContent() : freeTierContent()) : premiumTierContent()

  return (
    <CommonBanner title='Hosted VPN Locations'>
        <div className={styles.helpInfo}>
          <span><FaQuestionCircle /></span>&nbsp;&nbsp;Download configs for chosen locations and use them with any OpenVPN compatible client.
        </div>
        { content }
    </CommonBanner>
  )
}

export default HostedVpnBanner