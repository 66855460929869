import React from 'react';
import { useDispatch } from 'react-redux';
import { setPlansView } from '../../state/hostedVpnSlice';
import * as styles from "./banners.module.css";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

const TierLink = ({children, tier, premium}) => {
    const dispatch = useDispatch()
    const tierDescription = tier === undefined ? "" : tier.tierDescription

    const onLinkClick = () => {
        dispatch(setPlansView())
    }

    const linkStyle = premium ? styles.premiumLink : styles.tierLink

    return (
        <Tippy content={tierDescription}><span className={linkStyle} onClick={onLinkClick}>{ children }</span></Tippy>
    )
}

export default TierLink