import React from 'react';
import apiclient from "../../../js/apiclient"
import toast from "react-hot-toast"

const PromoCode = () => {
  const [code, setCode] = React.useState('')

  const handleSubmit = (e) => {
    e.preventDefault();
    apiclient.submitPromoCode(code).then(result => {
      if (result.ok) {
        window.location.reload()
      } else {
        toast.error("Cannot apply promo code!", {position: 'top-right', duration: 5000})
      }
    })
  }

  const handleChange = (e) => {
    const target = e.target
    const value = target.value

    setCode(value)
  }

  return <div style={{
    position: 'absolute',
    width: '100%',
    bottom: '0px',
    height: '35px'
  }}>
    <form style={{
      width: '100%',
      height: '100%'
    }} onSubmit={handleSubmit}>
      <input type="text" autoFocus={false} placeholder="Your promo code" name="promoCode" onInput={handleChange} style={{
        width: '80%',
        display: 'inline-block',
        boxSizing: 'border-box',
        height: '100%'
      }} />
      <button type="submit" style={{
        display: 'inline-block',
        width: '20%',
        boxSizing: 'border-box',
        border: 'none',
        backgroundColor: '#007bff',
        color: 'white',
        height: '100%',
        fontWeight: '800'
      }}>{">"}</button>
    </form>
  </div>
}

export default PromoCode;