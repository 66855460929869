import React from 'react';
import * as styles from "./mainview.module.css"
import { useState } from 'react';
import { useEffect } from 'react';
import HostedVpn from '../products/hostedvpn';
import AdminPanel from '../products/adminpanel';
import apiClient from '../../../../js/apiclient';
import ServiceItem from './serviceitem';
import util from '../../../../js/util';
import { useDispatch, useSelector } from 'react-redux';
import { setEmail, setUserName } from '../../../../state/profileSlice';
import { setCustomerPortal, setPriceTableId, setPublishableKey } from '../../../../state/stripeSlice';
import { IoSettingsSharp } from "react-icons/io5";
import { HiLogout } from "react-icons/hi";
import PromoCode from "../../../forms/promo/promo-code"

const MainView = () => {
  const dispatch = useDispatch()
  const logoutClickHandler = () => {
    apiClient.doLogout().then(res_ => util.navigateToPage("/"))
  }
  const settingsClickHandler = () => {
    util.navigateToPage("/settings")
  }
  const userName = useSelector((state) => state.profile.userName)
  const [services, setServices] = useState([])
  const [showUserOptsMenu, setShowUserOptsMenu] = useState(false)

  useEffect(() => {
    apiClient.getProducts()
      .then(resp => resp.json())
      .then(data => data.map(d => Object.create({id: d, name: d})))
      .then(res => {
        setServices(res)
        if (res.length > 0) {
          setSelectedService(res[0])
        }
      })
    apiClient.getUserProfile()
      .then(res => res.json())
      .then(res => {
        console.log("UserName: ", res.userName)
        dispatch(setUserName(res.userName))
        dispatch(setEmail(res.email))
      })
    apiClient.getStripePublicData()
      .then(res => res.json())
      .then(res => {
        dispatch(setPriceTableId(res.priceTableId))
        dispatch(setPublishableKey(res.publishableKey))
        dispatch(setCustomerPortal(res.customerPortal))
      })
  }, [])

  const getSelectedServiceComponent = (selected) => {
    switch(selected.id) {
      case "admin":
        return <AdminPanel />
      case "hostedVpn":
        return <HostedVpn />
      default:
        return <></>
    }
  }
  const [selectedService, setSelectedService] = useState({id: "", name: ""})
  const selectedServiceComponent = getSelectedServiceComponent(selectedService)

  const onServiceSelected = selected => {
    setSelectedService(selected)
  }

  const userOptsClickHandler = () => {
    setShowUserOptsMenu(!showUserOptsMenu)
  }

  return (
    <div className={styles.dashboard}>
      <aside>
        <div className={ styles.userWrap }>
          <div className={ styles.userThumb }></div><div className={ styles.userName }>{ userName }</div><div className={ styles.userOptions } onClick={ userOptsClickHandler }></div>
        </div>
        <div className={styles.servicesCaption}>
          Services
        </div>
        <ul style={{
          listStyleType: 'none',
          paddingInlineStart: '0'
        }}>
          {
            services.map(service => {
              return (
                <li key={ service.id }>
                  <ServiceItem
                    data={ service }
                    selectedServiceId={ selectedService.id }
                    onSelect={ onServiceSelected }
                  />
                </li>
              )
            })
          }
        </ul>
        <PromoCode />
      </aside>
      <main>
        { selectedServiceComponent }
      </main>
      <div className={ styles.userOptsMenu } style={{
        display: showUserOptsMenu ? "block" : "none"
      }}>
        <div>
          <div className={styles.upArrowBg}></div>
          <div className={styles.btnContainer}>
            <div className={ styles.userOptsBtn } onClick={ settingsClickHandler }><span><IoSettingsSharp />&nbsp;&nbsp;</span>Settings</div>
            <div className={ styles.userOptsBtn } onClick={ logoutClickHandler }><span><HiLogout /></span>&nbsp;&nbsp;Sign out</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainView